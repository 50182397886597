import React, { useState, useEffect } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { selecStaffMap } from "../../const-data/const-selecStaffMap";
import { Card, Button, Row, Col, FormGroup, FormLabel } from 'react-bootstrap';
import FormCompSelect from "./form-select";
import FormCompText from "../formik/form-text";  // Assuming this is the correct path for FormCompText
import { selecStg } from "../../functions/const-main";

import { BiReset } from "react-icons/bi";

const FormSelectLehrende = () => {
  const { values, setFieldValue } = useFormikContext();
  const [lehrende, setLehrende] = useState([]);
  const [filters, setFilters] = useState({});
  const [customInputs, setCustomInputs] = useState({});

  useEffect(() => {
    // Reset custom inputs whenever assignedLehrende length changes to match the array length
    setCustomInputs(
      values.assignedLehrende.map(() => false)
    );
  }, [values.assignedLehrende.length]);

  useEffect(() => {
    if (selecStaffMap) {
      const processedLehrende = Object.entries(selecStaffMap).map(([key, value]) => {
        const selecStgArray = value.sdef_studiengang_sV ? value.sdef_studiengang_sV.map(stg => stg.selecStg) : [];
        return {
          value: value.fullname,
          label: value.fullname,
          selecStg: selecStgArray,
        };
      });
      setLehrende(processedLehrende);
    }
  }, []);

  const filterOptions = (selecStg, index) => {
    setFilters({
      ...filters,
      [index]: selecStg,
    });
  };

  const getFilteredLehrende = (index) => {
    const selecStg = filters[index];
    let filteredOptions = selecStg
      ? lehrende.filter(item => Array.isArray(item.selecStg) && item.selecStg.includes(selecStg))
      : lehrende;

    // Add "Other" option at the end
    return [...filteredOptions, { value: "other", label: "Individuelle Angabe" }];
  };

  const handleSelectChange = (selectedValue, index) => {
    if (selectedValue === "other") {
      setCustomInputs(prev => prev.map((item, i) => (i === index ? true : item)));
      setFieldValue(`assignedLehrende[${index}].lehrende`, ""); // Prepare for custom input
    } else {
      setCustomInputs(prev => prev.map((item, i) => (i === index ? false : item)));
      setFieldValue(`assignedLehrende[${index}].lehrende`, selectedValue);
    }
  };

  const resetSelection = (index) => {
    setCustomInputs(prev => prev.map((item, i) => (i === index ? false : item)));
    setFieldValue(`assignedLehrende[${index}].lehrende`, ""); // Reset to empty to allow fresh selection
  };

  return (
    <FormGroup>
      <FormLabel><h3 style={{marginTop:'50px'}}>Lehrende</h3></FormLabel>
      <FieldArray name="assignedLehrende">
        {({ push, remove, move }) => (
          <div>
            {values.assignedLehrende.map((item, index) => {
              const isFirst = index === 0;
              const isLast = index === values.assignedLehrende.length - 1;

              return (
                <Card
                  key={`lehrende_${index}`}
                  style={{
                    padding: "0px!important",
                    border: "0px solid black",
                    backgroundColor: "white",
                    borderRadius: "0px!important",
                    borderBottom: "5px solid white",
                  }}
                >
                  <Row className="d-flex align-items-center" style={{ minHeight: '60px', marginBottom: "00px", padding: "0px", border: '0px solid red' }}>
                    <Col xs="auto" md="auto" className="d-flex" style={{ minWidth: "150px" }}>
                      {/* Remove marginTop and set fixed height */}
                      <Button
                        variant="danger"
                        onClick={() => remove(index)}
                        className="btn-remove"
                        disabled={values.assignedLehrende.length === 1}
                        style={{
                          marginRight: "5px",
                          float: "left",
                          minHeight: "100%",
                        }}
                      >
                        -
                      </Button>

                      <Button
                        variant="primary"
                        onClick={() => push({ lehrende: "" })}
                        className="btn-add"
                        style={{
                          marginRight: "5px",
                          float: "left",
                          minHeight: "100%",
                        }}
                      >
                        +
                      </Button>


                      

                      <Button
                        variant="secondary"
                        onClick={() => move(index, index - 1)}
                        disabled={isFirst}
                        className="btn-move"
                        style={{
                          marginRight: "5px",
                          float: "left",
                          minHeight: "100%",
                        }}
                      >
                        ↑
                      </Button>

                      <Button
                        variant="secondary"
                        onClick={() => move(index, index + 1)}
                        disabled={isLast}
                        className="btn-move"
                        style={{
                          marginRight: "5px",
                          float: "left",
                          minHeight: "100%",
                        }}
                      >
                        ↓
                      </Button>

                      <Button
                        variant="secondary"
                        onClick={() => resetSelection(index)}
                        className="btn-reset"
                        style={{
                          marginRight: "5px",
                          float: "left",
                          minHeight: "100%",
                        }}
                      >
                        <BiReset />
                      </Button>
                    </Col>

                    <Col xs={6} md={6} className="d-flex align-items-center">
                      {!customInputs[index] ? (
                        <FormCompSelect
                          name={`assignedLehrende[${index}].lehrende`}
                          labelText=""
                          options={getFilteredLehrende(index)}
                          onChange={(e) => handleSelectChange(e.target.value, index)}
                          value={item.lehrende}
                          showLabel={false}
                          style={{ height: "40px", width: "100%" }}
                        />
                      ) : (
                        <FormCompText
                          name={`assignedLehrende[${index}].lehrende`}
                          onChange={(e) => {
                            const { value } = e.target;
                            setFieldValue(`assignedLehrende[${index}].lehrende`, value);
                          }}
                          value={values.assignedLehrende[index].lehrende}
                          style={{ height: "40px", width: "100%" }}
                          showLabel={false}
                        />
                      )}
                    </Col>

                    <Col xs={3} md={3} className="d-flex align-items-center">
                      <FormCompSelect
                        name={`assignedLehrende[${index}].filter`}
                        labelText="Filter Studiengang:"
                        options={selecStg}
                        onChange={(e) => filterOptions(e.target.value, index)}
                        showLabel={false}
                        style={{ height: "40px", width: "100%" }}
                      />
                    </Col>
                  </Row>



                </Card>
              );
            })}
          </div>
        )}
      </FieldArray>
    </FormGroup>
  );
};

export default FormSelectLehrende;
