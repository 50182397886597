import React from 'react';
import { useField } from 'formik';
import { FormGroup, FormLabel } from 'react-bootstrap';

const FormSelect = ({ name, labelText, options, onChange, showLabel = true, storeFullObject = true }) => {
  const [field, meta, helpers] = useField(name);
  const { setValue, setTouched } = helpers;

  const handleChange = (e) => {
    const { value } = e.target;
    const selectedOption = options.find(option => option.value === value);
    setValue(storeFullObject ? selectedOption : value);
    setTouched(true);

    if (onChange) {
      onChange(e);
    }
  };

  const isSelected = field.value && (typeof field.value === 'object' ? field.value.value : field.value);
  const additionalClass = isSelected ? "form-selec-selected" : "";
  const additionalClass_label = isSelected ? "formgroup-selected" : "";

  return (
    <FormGroup className={`formgroup ${additionalClass_label}`} style={{ margin: 0, padding: 0 }}>
      {showLabel && <FormLabel htmlFor={name}>{labelText}</FormLabel>}
      <select
        id={name}
        className={`form-selec ${additionalClass}`}
        onChange={handleChange}
        value={typeof field.value === 'object' ? field.value.value : field.value || ""}
        style={{ height: "50px", width: "100%" }}
      >
        <option value="">{labelText}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </FormGroup>
  );
};

export default FormSelect;
