import {
  useField,
  Formik,
  Form,
  Field,
  FieldArray,
  ErrorMessage,
  useFormikContext,
} from "formik";
import React, { useRef, useState, useEffect, useReducer } from "react";
import { AiFillBehanceSquare } from "react-icons/ai";

// import { handleFileInputChange } from "../functions/func-handleFileInputChange.js";

import { FaXingSquare, FaLinkedin, FaInstagramSquare } from "react-icons/fa";
import { BsHeartPulseFill } from "react-icons/bs";
import { IoHelpBuoy } from "react-icons/io5";
import ReactDOMServer from 'react-dom/server';

// import { IoMail } from "react-icons/io5";
// import { ContentState, EditorState } from "draft-js";
// import htmlToDraft from "html-to-draftjs";
// import { TbWorldWww } from "react-icons/tb";
// import axios from "axios";


import QuillEditorStaff from "./quill-staff"; // Import the QuillEditor component
import classNames from "classnames"; // Import classnames library
import Handlebars from "handlebars";
import { formatHtml } from "../functions/handlebar/handlebarTemplateUtils";
import Icon_import from "./icons/icon-import";
import Icon_export from "./icons/icon-export";
import Icon_folder from "./icons/icon-folder";

import CatTitle from "./basic-elements/comp-cattitle";
import ContDescr from "./basic-elements/comp-cont-descr";
import DevContainer from "./basic-elements/comp-cont-dev"; // adjust the path as needed
import StudWFooter from "./studw/comp-studw-footer";
import BtnHelp from "./basic-elements/comp-btn-help";

import HeaderAbgabepaket from './abgabepaket/header-abgabepaket';

import {processImageFiles} from "../functions/formik/processfiles/func-processIMGFiles.js";
import {processPDFFiles} from "../functions/formik/processfiles/func-processPDFFiles.js";
import {processVarFiles} from "../functions/formik/processfiles/func-processVarFiles.js";
import {processCountArray} from "../functions/formik/processfiles/func-processCountArray.js";

import AccKontextangaben from './formik/accordions/comp-acc-kontextangaben';
import AccProjektangaben from "./formik/accordions/comp-acc-projektangaben.js";
import AccDateienPDF from "./formik/accordions/comp-acc-pdfdateien";
import AccDateienBilderVideos from './formik/accordions/comp-acc-bildervideo.js';
import AccZusatzdateien from './formik/accordions/comp-acc-zusDateien';
import AccPersKont from './formik/accordions/comp-acc-persKontakt';
import AccTemplateVorschau from './formik/accordions/comp-acc-temp-preview';
import AccFeedback from "./formik/accordions/comp-acc-feedback";

import TemplatePreview from "./comp-templatepreview";
import TemplatePreviewReact from "./templates/comp-temp/comp-temp-preview";
import TemplateInsta from './templates/temp-insta.js'; // Import the new template component
import TemplatePreviewYT from "./comp-templatepreview-yt";
import {
  seafile_uploadFileSW,
  createDirectory,
} from "../functions/const-seafile-upload-SW"; // Adjust the path as necessary

//Const Data based on .json Files
import { selecModMap } from "../const-data/const-selecModMap";
import { tags } from "../functions/const-tags";
import initialValues_Student_Work from "../functions/formik/initialValues-student-work";
import { getUnixTimestamp } from "../functions/func-getunixtimestamp";
import { parseTimestamp, parseTimestampFolder, } from "../functions/const-seafile";
// import { selecStgMap } from "../const-data/const-selecStgMap";
// import { selecStaffMap } from "../const-data/const-selecStaffMap";
// import FileInput from "./fileinput-studwork";
// import ResolutionDisplay from "./comp-ResolutionDisplayStudWork";

import {
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  FormSelect,
  Accordion,
  Card,
  Tab,
  Tabs,
  Row,
  Nav,
  Col,
} from "react-bootstrap";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import AddRemoveButtons from './components/formik/form-button-add';

import JSZip from "jszip";
import {
  difficulty,
  moduldauer,
  projektart,
} from "../functions/const-stud-work";
import {
  selecStg,
  selecStgSem,
  selecStgType,
  selecSemType,
  getCustomValueofOptionList,
} from "../functions/const-main";


import FormCompCheckbox from "./formik/form-checkbox";

// Functions
import { generateYearOptions, getCurrentYear, } from "../functions/formik/select-generate-year";
import { transformModuleValue } from "../functions/formik/filename-value"; // Import the externalized function
import { pdfReducer, imgReducer, varfileReducer, countarrayReducer, } from "../functions/formik/fileReducer";


Handlebars.registerHelper("removeSpaces", function (value) {
  return value.replace(/\s+/g, "");
});

Handlebars.registerHelper("capitalizeFirst", function (value) {
  if (value && typeof value === "string") {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
  return value;
});

const StudentWork = () => {
  const fileInputRef = useRef();
  const [pdfState, pdfDispatch] = useReducer(pdfReducer, {});
  const [imgState, imgDispatch] = useReducer(imgReducer, {});
  const [countarrayState, countarrayDispatch] = useReducer(
    countarrayReducer,
    {}
  );
  const [varfileState, varfileDispatch] = useReducer(varfileReducer, {});

  const [imageData, setImageData] = useState({});
  const [PDFfileName, setFileName] = useState(""); // State variable for the filename
  const yearOptions = generateYearOptions();
  const currentYear = getCurrentYear();


  const [templateContent, setTemplateContent] = useState(null);
  const [renderedTemplate, setRenderedTemplate] = useState("");

  const [uploadInfo, setUploadInfo] = useState([]);

  const [acc_Seafile_showAccordion, acc_Seafile_setShowAccordion] =
    useState(false);
  const [acc_Seafile_activeKey, acc_Seafile_setActiveKey] = useState(null);

  const [courses, setCourses] = useState([]);
  const [manualTrigger, setManualTrigger] = useState(false); // State to trigger useEffect
  const [lehrende, setLehrende] = useState([]);
  const [filteredLehrende, setFilteredLehrende] = useState([]);
  const [imgnaming] = useState([]);



  const [formValues, setFormValues] = useState({
    accordion1: {
      projektart: "",
      jahr: "",
      semestertype: "",
      selecStgType: "",
      selecStg: "",
    },
    accordion2: {
      ger_project_titel: "",
      ger_project_subtitel: "",
      ger_descr_projekt_short: "",
    },
  });

  const [isValid, setIsValid] = useState({
    accordion1: false,
    accordion2: false,
  });

  const handleInputChange = (accordion, field, value) => {
    console.log(`Updating ${accordion} - ${field}: ${value}`);
    setFormValues((prevValues) => ({
      ...prevValues,
      [accordion]: {
        ...prevValues[accordion],
        [field]: value,
      },
    }));
  };

  const generateImgNaming = (values) => {
    const customSemestertype = getCustomValueofOptionList(
      values.semestertype,
      selecSemType
    );
    const customselecStgType = getCustomValueofOptionList(values.selecStgType, selecStgType) || "default";
    console.log("Custom selecStgType:", customselecStgType); // Log customselecStgType
    const transformedModule = transformModuleValue(values.modul);

    const namesConcat = values.persondata
      .map((person) => {
        let vorname = person.vorname || ""; // Default to empty string if undefined
        let nachname = person.nachname || ""; // Default to empty string if undefined
        if (values.persondata.length > 2) {
          vorname = vorname.substring(0, 3); // Only apply substring if non-empty
          nachname = nachname.substring(0, 3);
        }
        return `${vorname}${nachname}`;
      })
      .join("-");

    // Use values.projektart.value for the filename generation
    return `${values.jahr}${customSemestertype}-${customselecStgType}_${transformedModule}_${namesConcat}_${values.projektart?.value || ""}`;
  };




  const handleImageChange = (
    name,
    { preview, resolution, base64, filename }
  ) => {
    setImageData({
      ...imageData,
      [name]: { preview, resolution, base64, filename },
    });
  };

  // Step 4: Implement a function to handle file input change and load JSON content
  // Assume this function is now part of a component or has access to `values` from Formik
  const handleFileInputChange = async (
    event,
    setFieldValue,
    setTouched,
    setManualTrigger
  ) => {
    const file = event.target.files[0];
    console.log("Selected file:", file);

    if (file) {
      try {
        const zip = new JSZip();
        const content = await zip.loadAsync(file);
        const fileKeys = Object.keys(content.files);
        console.log("Processing ZIP file with keys:", fileKeys);

        let jsonContent = null;

        for (const key of fileKeys) {
          console.log(`File Key: ${key}`);
          if (key.endsWith(".json")) {
            const jsonString = await content.files[key].async("string");
            jsonContent = JSON.parse(jsonString);
            console.log("Parsed JSON content:", jsonContent);
            break;
          }
        }

        if (jsonContent) {
          const touchedFields = {};
          const newFormValues = { ...formValues };

          // Update all fields in JSON content
          for (const field in jsonContent) {
            if (jsonContent[field] !== undefined) {
              setFieldValue(field, jsonContent[field], true); // Validate field on change
              touchedFields[field] = true;
              console.log(
                `Set Formik field: ${field} to value: ${jsonContent[field]}`
              );

              // Update the local formValues state
              if (newFormValues.accordion1.hasOwnProperty(field)) {
                newFormValues.accordion1[field] = jsonContent[field];
              } else if (newFormValues.accordion2.hasOwnProperty(field)) {
                newFormValues.accordion2[field] = jsonContent[field];
              } else {
                // Add fields outside of accordion1 and accordion2 to the root of newFormValues
                newFormValues[field] = jsonContent[field];
              }
            }
          }

          console.log("Updated formValues:", newFormValues);

          setTouched(touchedFields);
          setFormValues(newFormValues); // Set the updated form values
          setManualTrigger((prev) => !prev); // Update manual trigger state to force useEffect re-run
          // First process and update count arrays

          await processImageFiles(fileKeys,jsonContent,content,setFieldValue,imgDispatch);
          await processPDFFiles(fileKeys, jsonContent, content, setFieldValue, pdfDispatch);
          await processVarFiles(fileKeys, jsonContent, content, setFieldValue, varfileDispatch);
          await processCountArray(jsonContent,countarrayDispatch, setFieldValue);
        }
      } catch (error) {
        console.error("Error processing ZIP file:", error);
      }
    }
  };

  useEffect(() => {
    const validateAccordion = (accordion) => {
      return Object.values(formValues[accordion]).every((value) =>
        typeof value === "string" ? value.trim() !== "" : value !== ""
      );
    };

    const newIsValid = {
      accordion1: validateAccordion("accordion1"),
      accordion2: validateAccordion("accordion2"),
    };
    setIsValid(newIsValid);

    // console.log("Accordion Validation State:", newIsValid);
    // console.log("Form Values:", formValues);
  }, [formValues]);

  // useEffect to process courses based on selection
  useEffect(() => {
    const selectedStg = formValues.accordion1?.selecStg;
    const selectedStgType = formValues.accordion1?.selecStgType;

    if (selectedStg && selectedStgType) {
      console.log("Selected Stg:", selectedStg);
      console.log("Selected Stg Type:", selectedStgType);

      const processedCourses = Object.entries(selecModMap)
        .filter(
          ([key, value]) =>
            value.selecStg === selectedStg &&
            value.selecStgType === selectedStgType
        )
        .map(([key, value]) => ({
          value: value.modulnr,
          label: `${value.modulnr} - ${value.ger_modultitel}`,
        }));
      setCourses(processedCourses);

      console.log("Processed Courses:", processedCourses);
    } else {
      const allCourses = Object.entries(selecModMap).map(([key, value]) => ({
        value: value.modulnr,
        label: `${value.modulnr} - ${value.ger_modultitel}`,
      }));
      setCourses(allCourses);
    }
  }, [
    formValues.accordion1?.selecStg,
    formValues.accordion1?.selecStgType,
    manualTrigger,
  ]); // Add manualTrigger to dependencies

  //use effect to change accordion appearance based on validation
  useEffect(() => {
    const validateAccordion = (accordion) => {
      return Object.values(formValues[accordion]).every(
        (value) => value.trim() !== ""
      );
    };

    const newIsValid = {
      accordion1: validateAccordion("accordion1"),
      accordion2: validateAccordion("accordion2"),
    };
    setIsValid(newIsValid);

    console.log("Accordion Validation State:", newIsValid);
    console.log("Form Values:", formValues);
  }, [formValues]);

  function getMaxInputCount(jsonContent) {
    let maxInputCount = 0;
    for (const key in jsonContent) {
      if (key.endsWith("_inputCount") && typeof jsonContent[key] === "number") {
        maxInputCount = Math.max(maxInputCount, jsonContent[key]);
      }
    }
    return maxInputCount;
  }

  // async function processCountArray(
  //   jsonContent,
  //   countarrayDispatch,
  //   setFieldValue
  // ) {
  //   const maxCounts = {};

  //   // First, accumulate the maximum counts for each field
  //   for (const field in jsonContent) {
  //     if (field.endsWith("_inputCount")) {
  //       const baseFieldName = field.replace("_inputCount", "");
  //       const count = parseInt(jsonContent[field], 10);
  //       if (!isNaN(count)) {
  //         if (!maxCounts[baseFieldName] || count > maxCounts[baseFieldName]) {
  //           maxCounts[baseFieldName] = count;
  //         }
  //       }
  //     }
  //   }

  //   // Dispatch the maximum counts and update Formik values for each field
  //   for (const baseFieldName in maxCounts) {
  //     const count = maxCounts[baseFieldName];
  //     const fieldName = `${baseFieldName}_inputCount`; // Adjust if your naming convention differs
  //     countarrayDispatch({
  //       type: "SET_MAX_INPUT_COUNT",
  //       fieldName: baseFieldName,
  //       count: count,
  //     });
  //     setFieldValue(fieldName, count); // Set the value in Formik's state
  //     console.log(`Set Formik field: ${fieldName} to value: ${count}`);
  //   }

  //   console.log("highest number of inputs:", maxCounts);
  //   console.log(
  //     "Processed maximum input counts and updated state with the highest values."
  //   );
  // }



  //function used for importing IMGs inside ZIP File
  // async function processImageFiles(
  //   fileKeys,
  //   jsonContent,
  //   content,
  //   setFieldValue,
  //   values
  // ) {
  //   const maxInputCount = getMaxInputCount(jsonContent); // Use the function to get the max count
  //   console.log(`Max Input Count: ${maxInputCount}`);

  //   for (const key of fileKeys) {
  //     console.log(`Processing Image File: ${key}`);
  //     if (key.match(/\.(jpg|jpeg|png|svg)$/)) {
  //       for (const field in jsonContent) {
  //         if (jsonContent[field] === key) {
  //           console.log(`Matching JSON field: ${field}`);
  //           const blob = await content.files[key].async("blob");
  //           const fileType = key.split(".").pop();
  //           const imgUrl = URL.createObjectURL(
  //             new Blob([blob], { type: `image/${fileType}` })
  //           );

  //           const formikField = field.replace("_filename", "");

  //           const imgFile = new File([blob], jsonContent[field], {
  //             type: `image/${fileType}`,
  //           });
  //           setFieldValue(formikField, imgFile);
  //           setFieldValue(`${formikField}_url`, imgUrl);
  //           console.log(
  //             `Set Formik field: ${formikField} to Image file and URL: ${imgUrl}`
  //           );
  //           imgDispatch({
  //             type: "SET_IMG_URL",
  //             fieldName: formikField,
  //             imgUrl,
  //           });
  //           console.log("Dispatched SET_IMG_URL action:", {
  //             fieldName: formikField,
  //             imgUrl,
  //           });
  //         }
  //       }
  //     }
  //   }
  // }

  //function used for importing PDFs inside ZIP File
  // async function processPDFFiles(
  //   fileKeys,
  //   jsonContent,
  //   content,
  //   setFieldValue
  // ) {
  //   for (const key of fileKeys) {
  //     if (key.endsWith(".pdf")) {
  //       for (const field in jsonContent) {
  //         if (jsonContent[field] === key) {
  //           const blob = await content.files[key].async("blob");
  //           const pdfUrl = URL.createObjectURL(
  //             new Blob([blob], { type: "application/pdf" })
  //           );

  //           const formikField = field.replace("_filename", "");

  //           const pdfFile = new File([blob], jsonContent[field], {
  //             type: "application/pdf",
  //           });
  //           setFieldValue(formikField, pdfFile);
  //           setFieldValue(`${formikField}_url`, pdfUrl);
  //           console.log(
  //             `Set Formik field: ${formikField} to PDF file and URL: ${pdfUrl}`
  //           );
  //           pdfDispatch({
  //             type: "SET_PDF_URL",
  //             fieldName: formikField,
  //             pdfUrl,
  //           });
  //           console.log("Dispatched SET_PDF_URL action:", {
  //             fieldName: formikField,
  //             pdfUrl,
  //           });
  //         }
  //       }
  //     }
  //   }
  // }

  // Function to process any file type inside the ZIP File
  // async function processVarFiles(
  //   fileKeys,
  //   jsonContent,
  //   content,
  //   setFieldValue
  // ) {
  //   // Iterate over each file key in the array of file keys
  //   for (const key of fileKeys) {
  //     console.log(`Processing Var File: ${key}`);

  //     // Check if the file key does not match the specified file extensions (pdf, jpg, jpeg, png, json)
  //     if (!key.match(/\.(pdf|jpg|jpeg|png|svg|json)$/)) {
  //       // Iterate over each field in the JSON content
  //       for (const field in jsonContent) {
  //         // If the value of the JSON field matches the current file key
  //         if (jsonContent[field] === key) {
  //           console.log(`Matching JSON field: ${field}`);

  //           try {
  //             // Extract the file as a blob object from the zip content
  //             const blob = await content.files[key].async("blob");
  //             // Create a URL for the blob object
  //             const fileUrl = URL.createObjectURL(blob);

  //             // Remove the '_filename' suffix to get the formik field name
  //             const formikField = field.replace("_filename", "");

  //             // Extract the file extension from the key or use 'octet-stream' as a default type
  //             const fileType = key.split(".").pop() || "octet-stream";
  //             // Create a new File object with the blob content
  //             const varFile = new File([blob], jsonContent[field], {
  //               type: blob.type || `application/${fileType}`,
  //             });

  //             // Ensure the filename field is updated with the complete file name including extension
  //             const completeFileName = key; // Using the key directly to get the complete filename
  //             setFieldValue(`${formikField}_filename`, completeFileName);
  //             console.log(
  //               `Set Formik field: ${formikField}_filename to complete file name: ${completeFileName}`
  //             );

  //             // Dispatch an action to update the state with the file URL
  //             varfileDispatch({
  //               type: "SET_VAR_FILE_URL",
  //               fieldName: formikField,
  //               varUrl: fileUrl,
  //             });
  //             varfileDispatch({
  //               type: "SET_VAR_FILE_NAME",
  //               fieldName: formikField,
  //               fileName: completeFileName,
  //             });
  //             console.log(
  //               "Dispatched SET_VAR_FILE_URL and SET_VAR_FILE_NAME actions:",
  //               {
  //                 fieldName: formikField,
  //                 varUrl: fileUrl,
  //                 fileName: completeFileName,
  //               }
  //             );

  //             // Set the formik field value to the File object
  //             setFieldValue(formikField, varFile);
  //             console.log(
  //               `Set Formik field: ${formikField} to file and URL: ${fileUrl}`
  //             );
  //             // Set the URL of the formik field
  //             setFieldValue(`${formikField}_url`, fileUrl);

  //             // Additional verification log to check the Formik state
  //             console.log(`Verified Formik state for ${formikField}:`, {
  //               file: varFile,
  //               url: fileUrl,
  //             });
  //           } catch (error) {
  //             // Log any errors encountered during the file processing
  //             console.error(`Error processing file ${key}:`, error);
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  const handleSubmitSF = async (values, setValues, setUploadInfo) => {
    console.log("EXPORT IS STARTING NOW");
    console.log("Form values:", values);

    // Debugging statements to check the values
    console.log("values.jahr:", values.jahr);
    console.log("values.selecStg:", values.selecStg);

    const timestamp = getUnixTimestamp();
    const parsed_timestamp = parseTimestampFolder(timestamp);

    // Generate directory name based on form values
    const dirName = generateImgNaming(values);
    const year = values.jahr || "unknown_year";
    const stg = values.selecStg || "unknown_stg";
    const baseUploadPath = process.env.REACT_APP_SF_up_BAMAFW_endpath || "/";
    const yearDir = `${baseUploadPath}${year}/`;
    const stgDir = `${yearDir}${stg}/`;
    const subDirPath = `${stgDir}${dirName}_${parsed_timestamp}/`;

    console.log("baseUploadPath:", baseUploadPath);
    console.log("Year Directory Path:", yearDir);
    console.log("Study Group Directory Path:", stgDir);
    console.log("Sub Directory Path:", subDirPath);

    // Check if environment variables are set correctly
    if (
      !process.env.REACT_APP_SF_proxyUrl ||
      !process.env.REACT_APP_SF_Server
    ) {
      console.error("Missing required environment variable.");
      return;
    }

    // Create directories
    await createDirectory(yearDir);
    await createDirectory(stgDir);
    await createDirectory(subDirPath);

    // Upload files concurrently
    const fileUploadPromises = Object.keys(values).map(async (key) => {
      if (values[key] instanceof File) {
        const fileField = key;
        const filenameField = `${fileField}_filename`;
        const file = values[key];
        const fileType = file.type.split("/")[1]; // Extracts the file type (pdf, jpg, png)

        console.log(`${filenameField} values:`, values[filenameField]);

        let newFileName = values[filenameField] || `defaultName.${fileType}`;
        console.log(`Uploading file: ${newFileName}`);

        // Upload the file to Seafile
        await seafile_uploadFileSW(
          file,
          newFileName,
          subDirPath,
          (progress) => {
            console.log(`Upload progress for ${newFileName}: ${progress}%`);
          },
          (info) => {
            console.log("Upload info:", info);
          }
        );
      }
    });

    await Promise.all(fileUploadPromises);

    // Combine the existing values with additional data
    const combinedData = {
      ...values,
      timestamp: timestamp,
      parsed_timestamp: parseTimestamp(timestamp),
      filename: `${dirName}_${timestamp}`,
    };

    // Stringify the combined data
    const jsonData = JSON.stringify(combinedData, null, 2);
    const jsonBlob = new Blob([jsonData], { type: "application/json" });
    const jsonFile = new File([jsonBlob], `${dirName}_data.json`);

    // Upload the JSON file to Seafile
    await seafile_uploadFileSW(
      jsonFile,
      `${dirName}_data.json`,
      subDirPath,
      (progress) => {
        console.log(`Upload progress for JSON data: ${progress}%`);
      },
      (info) => {
        console.log("Upload info:", info);
      }
    );

    // Optionally update Formik's state
    if (setValues) {
      setValues(combinedData);
    }
  };

  const handleSubmit = async (values, { setValues }) => {
    console.log("EXPORT IS STARTING NOW");
    console.log("Form values:", values); // Log to check the structure of values
    const ZIPfilename = generateImgNaming(values);
    const zip = new JSZip();

    // Dynamically find and process all relevant file fields
    Object.keys(values).forEach((key) => {
      if (values[key] instanceof File) {
        const fileField = key;
        const filenameField = `${fileField}_filename`;
        const file = values[fileField];

        // Extract the file extension from the file name
        const fileExt = file.name.split('.').pop(); // Directly get the extension from the filename
        console.log(`Extracted file extension for ${file.name}: ${fileExt}`);

        // Construct the filename with the correct extension
        let newFileName = values[filenameField] || `${ZIPfilename}_${fileField}.${fileExt}`;
        if (!newFileName.endsWith(`.${fileExt}`)) {
          newFileName += `.${fileExt}`; // Append the extracted file extension if missing
        }

        console.log(`Renaming and adding file: ${newFileName}`);
        zip.file(newFileName, file); // Save the file under the new name in the ZIP

        // Update the filename field in values to ensure it has the correct extension
        values[filenameField] = newFileName;
      }
    });

    // Combine the existing values with additional data
    const timestamp = getUnixTimestamp();
    const combinedData = {
      ...values,
      timestamp: timestamp,
      parsed_timestamp: parseTimestamp(timestamp),
      filename: `${ZIPfilename}_${timestamp}`,
    };

    // Stringify the combined data
    const jsonData = JSON.stringify(combinedData, null, 2);

    // Add the JSON file with form data to the ZIP
    zip.file(`${ZIPfilename}_data.json`, jsonData);

    // Define the templates to fetch and process
    const Handlebars = require("handlebars");

    // Register the Handlebars helper using the externalized formatHtml function
    Handlebars.registerHelper("formatHtml", function (htmlContent) {
      return formatHtml(htmlContent); // Use the externalized formatHtml function
    });

    // Define the templates to fetch and process
    const templates = {
      metadata_template: "./templates/metadata-template.hbs", // Handlebars format
    };

    // Conditionally include templates based on form values
    Object.assign(
      templates,
      values["check_youtube_allowance"] === true
        ? { youtube_template: "./templates/youtube-template.hbs" }
        : {
          "DO-NOT-PUBLISH-ON-YOUTUBE":
            "./templates/DO-NOT-PUBLISH-ON-YOUTUBE.hbs",
        },
      values["url_insta_allowance"] === true
        ? { TemplateInsta: "react-insta-template" } // Use identifier for React-based template
        : {}
    );

    // Function to fetch, compile, and add a template to the ZIP
    const fetchAndProcessTemplate = async (templateKey) => {
      const templatePath = templates[templateKey];

      // Check if the template is the React-based Instagram template
      if (templatePath === "react-insta-template") {
        console.log(`Using React component for template: ${templateKey}`);

        // Render the InstaTemplate React component to static HTML
        const htmlContent = ReactDOMServer.renderToStaticMarkup(<TemplateInsta values={values} />);

        // Convert to plain text by stripping HTML tags
        const formattedTemplate = formatHtml(htmlContent);

        // Add the processed and formatted template to the ZIP file
        zip.file(`${ZIPfilename}_${templateKey}.txt`, formattedTemplate);
      } else {
        console.log(`Fetching Handlebars template from: ${templatePath}`);

        try {
          const templateResponse = await fetch(templatePath);
          const templateText = await templateResponse.text();

          // Compile the Handlebars template
          const template = Handlebars.compile(templateText);

          // Use 'values' directly from Formik for template data
          const processedTemplate = template(values);

          // Apply the formatHtml helper to the entire processed template
          const formattedTemplate =
            Handlebars.helpers.formatHtml(processedTemplate);

          // Add the processed and formatted template to the ZIP file
          zip.file(`${ZIPfilename}_${templateKey}.txt`, formattedTemplate);
        } catch (error) {
          console.error(
            `Error fetching or processing template ${templateKey}:`,
            error
          );
        }
      }
    };

    // Fetch and process all templates
    await Promise.all(
      Object.keys(templates).map((templateKey) =>
        fetchAndProcessTemplate(templateKey)
      )
    );

    // Generate ZIP file and trigger download
    zip
      .generateAsync({ type: "blob" })
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${ZIPfilename}.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error creating ZIP file:", error);
      });
  };



  return (
    <Formik initialValues={initialValues_Student_Work} onSubmit={handleSubmit}>
      {({ values, handleChange, setValues, setFieldValue, setTouched }) =>
        (() => {
          // console.log("Current Environment:", process.env.NODE_ENV);
          const imgnaming = generateImgNaming(values);
          const alumni = values.alumni;

          //combination of onchange effects for lehrende field
          //handleSelect for Lehrende Field
          const handleSelectChange = (e) => {
            const { value } = e.target;
            console.log("Selected Value:", value);
            setFieldValue("accordion1.lehrende", value);
          };

          return (
            <Form>
              <FormGroup>
                <HeaderAbgabepaket
                  acc_Seafile_setShowAccordion={acc_Seafile_setShowAccordion}
                  acc_Seafile_setActiveKey={acc_Seafile_setActiveKey}
                  handleSubmit={handleSubmit}
                  values={values}
                  setValues={setValues}
                  fileInputRef={fileInputRef}
                />

                {process.env.NODE_ENV === "development" && (
                  <DevContainer>
                    <b>Dateibenennung Vorschau:</b> {imgnaming}
                  </DevContainer>
                )}

                <div style={{ marginBottom: "0px" }}>
                  <input
                    type="file"
                    accept=".json,.zip"
                    onChange={(event) =>
                      handleFileInputChange(
                        event,
                        setFieldValue,
                        setTouched,
                        setManualTrigger
                      )
                    }
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                </div>
              </FormGroup>
              <Row>
                <Col md="6" xs="12">
                  <FormCompCheckbox
                    name="eng_transcr"
                    labelText="Englische Übersetzung hinterlegen"
                  />
                </Col>

                <Col md="6" xs="12">
                  <FormCompCheckbox
                    name="alumni"
                    labelText="Ich bin MA / BA AbsolventIn oder mache dieses Semester meinen Abschluss"
                  />
                </Col>
              </Row>

              {/* <Row>
                {acc_Seafile_showAccordion === true && (
                  <Accordion flush activeKey={acc_Seafile_activeKey}>
                    <Accordion.Item eventKey="0" className="acc_upload">
                      <Accordion.Header>
                        <img
                          src="./img/icons/icon_upload.svg"
                          className="icon_up"
                          alt="File Icon"
                        />
                        <h2>Seafile Upload</h2>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ContDescr>
                          Bitte lade das eben exportierte .zip auf Seafile hoch. Du findest das Zip in deinem Downloadordner
                        </ContDescr>
                        <iframe
                          src="https://seafile.rlp.net/u/d/b9ac2bcdaf5245269954/"
                          width="100%"
                          height="600"
                          frameborder="0"
                          allowfullscreen
                        ></iframe>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )}
              </Row> */}

              <CatTitle IconComponent={Icon_export} titleText="Metadaten" />

              <AccKontextangaben
                isValid={isValid}
                projektart={projektart}
                yearOptions={yearOptions}
                selecSemType={selecSemType}
                selecStgType={selecStgType}
                selecStg={selecStg}
                courses={courses}
                lehrende={lehrende}
                filteredLehrende={filteredLehrende}
                values={values}
                handleSelectChange={handleSelectChange}
                setFieldValue={setFieldValue}
                handleInputChange={handleInputChange}
              />
              <AccProjektangaben
                isValid={isValid}
                tags={tags}
                values={values}
                setFieldValue={setFieldValue}
                handleInputChange={handleInputChange}
              />
              <AccPersKont
                selecStgSem={selecStgSem}
                handleInputChange={handleInputChange}
                setFieldValue={setFieldValue}
                alumni={alumni}
              />
              <CatTitle IconComponent={Icon_folder} titleText="Dateien" />
              <AccDateienPDF imgnaming={imgnaming} pdfDispatch={pdfDispatch} pdfState={pdfState} />
              <AccDateienBilderVideos imgnaming={imgnaming}
                imgDispatch={imgDispatch}
                imgState={imgState}
                varfileDispatch={varfileDispatch}
                varfileState={varfileState}
              />
              <AccZusatzdateien
                imgnaming={imgnaming}
                varfileDispatch={varfileDispatch}
                varfileState={varfileState}
              />
              <CatTitle IconComponent={Icon_folder} titleText="Templates" />
              <AccTemplateVorschau values={values} />

              <CatTitle IconComponent={Icon_folder} titleText="Feedback" />
              <AccFeedback
        values={values}
        setFieldValue={setFieldValue}
        handleInputChange={handleInputChange}
      />


              <Row>
                <Col>
                  <Button
                    variant="primary"
                    type="button"
                    className="custom-button"
                    style={{
                      height: "60%",
                      minWidth: "150px",
                      width: "100%",
                      minHeight: "50px",
                    }}
                    onClick={() => {
                      acc_Seafile_setShowAccordion(true); // Toggle visibility state
                      acc_Seafile_setActiveKey("0"); // Set the Accordion item to open automatically
                      handleSubmit(values, setValues);
                    }}
                  >
                    <Icon_export width="18px" />.zip Datei exportieren
                  </Button>
                </Col>
                <Col>
                </Col>
                <Col></Col>
              </Row>
              {process.env.NODE_ENV === "development" && (
                <DevContainer>
                  <b>Vorschau Export Texte:</b> {imgnaming} / Projektart:{" "}
                  {values.projektart.valuefull} / Studiengang:{" "}
                  {values.selecStg.valuefull}
                </DevContainer>
              )}
              <StudWFooter />
            </Form>
          );
        })()
      }
    </Formik>
  );
};
export default StudentWork;